module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#DCDCDD',

  text: '#333333',

  primary: '#4C5C68',
  primaryLight: '#C5C3C6',
  primaryDark: '#46494C',

  secondary: '#1EA1C2',
  secondaryLight: '#DCDCDD',
  secondaryDark: '#4C5C68',
};
